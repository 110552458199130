
    <div class="team-grid" style="margin: 10% 20px 20px;">
      <div class="container" data-aos="fade-up" data-aos-anchor-placement="center-center">
          <div class="intro">
              <h2 class="text-center">Zbiórki

              </h2>


          </div>
      </div>
  </div>

  <hr style="width: 40%;">
  <div class="container">

    <div class="row" style="margin: 20px 0px 20px;">
        <div class="col-md-12" data-aos="fade-right"> <img class="d-block w-100" style="display: block;
        margin: 0 auto; border-radius: 5px; " src="../assets/img/map.png">

        </div>
    </div>

</div>

  <div>
      <div class=" container ">
          <div class="row ">
              <div class="col-md-5">
                <h4 style="text-align: center;">Gromada Zuchowa Morskie Lwy</h4>

                <p style=" text-align: justify; ">Gromada zuchowa dla dzieci w wieku 7-10 lat. Zbiorki zuchowe odbywają się we wtorki od 17:55 do
19:55, West Credit Secondary School (adres 6325 Montevideo Rd, Mississauga, ON L5N 4G7).</p>
               
              </div>

              <div class="col-md-7" data-aos="zoom-out">                
                  <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=%20West%20Credit%20Secondary%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe><a href="https://www.maps.ie/draw-radius-circle-map/">Measure km radius</a></div>
 <!-- <img class="d-block w-25" style="display: block;
                  margin: 0 auto; border-radius: 5px; " src="../assets/img/13sta.jpg"> -->
              </div>

          </div>
      </div>
  </div>    
  <div style="margin-top: 20px;">
      <div class=" container ">
          <div class="row ">
              
            <div class="col-md-5" data-aos="zoom-out">
                <h4 style="text-align: center;">Drużyna 16sta</h4>

                <p style=" text-align: justify; ">16ta Żeglarska Drużyna Harcerska im. Bolesława Chrobrego dla starszych harcerzy (w wieku 14-16).
                  Zbiórki się odbywają we wtorki od 17:55 do
                  19:55, West Credit Secondary School (adres 6325 Montevideo Rd, Mississauga, ON L5N 4G7).</p>
                <img class="d-block w-25" style="display: block;
              margin: 0 auto; border-radius: 5px; " src="../assets/img/16sta.jpg">
            </div>
              <div class="col-md-7 custom-container">
                <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=%20West%20Credit%20Secondary%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe><a href="https://www.maps.ie/draw-radius-circle-map/">Measure km radius</a></div>

              </div>


          </div>
      </div>
  </div>

  <div style="margin-top: 20px;">
      <div class=" container ">
          <div class="row ">
            <div class="col-md-5" data-aos="zoom-out">
                <h4 style="text-align: center;">Drużyna 35ta</h4>

                <p style=" text-align: justify; ">35ta Żeglarska Drużyna Harcerska im. Zygmunta Augusta dla młodszych harcerzy (w wieku 11-13).
                  Zbiórki się odbywają we wtorki od 17:55 do
                  19:55, West Credit Secondary School (adres 6325 Montevideo Rd, Mississauga, ON L5N 4G7).
                </p>  
                <img class="d-block w-25" style="display: block;
            margin: 0 auto; border-radius: 5px; " src="../assets/img/35ta.png">
            </div>
              <div class="col-md-7 custom-container">
                <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=%20West%20Credit%20Secondary%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe><a href="https://www.maps.ie/draw-radius-circle-map/">Measure km radius</a></div>

              </div>

             

          </div>
      </div>
  </div>


  <div style="margin: 20px 20px 20px;">
      <div class=" container ">
          <div class="row ">
            <div class="col-md-5" data-aos="zoom-out">
                <h4 style="text-align: center;">Drużyna 3cia</h4>

                <p style=" text-align: justify; "> 3cia Żeglarska Drużyna Wędrownicza im. Władysława Wagnera dla wędrowników (w wieku 16+).
                  Lokalizacje i detale zbiorek są zależne od programu i uczestników w obecnym roku, i się często
                  zmieniają.
                <img class="d-block w-25" style="display: block;
          margin: 0 auto; border-radius: 5px; " src="../assets/img/3cia.jpg">
            </div>
              <div class="col-md-7">
                  <img class="d-block w-100" style="display: block;
                margin: 0 auto; border-radius: 5px; " src="../assets/img/zbiorki5.jpg">
              </div>

              

          </div>
      </div>

      <hr>
      <div style="margin: 20px 20px 20px;">
          <div class="container">

              <div class="row" style="margin: 20px 0px 20px;">
                  <div class="col-md-6" data-aos="fade-right"> <img class="d-block w-100" style="display: block;
                  margin: 0 auto; border-radius: 5px; " src="../assets/img/zbiorki1.jpg">

                  </div>
                  <div class="col-md-6" data-aos="fade-left"> <img class="d-block w-100" style="display: block;
                  margin: 0 auto; border-radius: 5px; padding-top: 10px; " src="../assets/img/zbiorki2.jpg">

                  </div>
              </div>
              <div class="row">
                  <div class="col-md-4" data-aos="fade-right"> <img class="d-block w-100" style="display: block;
                margin: 0 auto; border-radius: 5px; " src="../assets/img/zbiorki3.jpg">
                      <img class="d-block w-100" style="display: block;
                margin: 10px 10px 10px ; border-radius: 5px; " src="../assets/img/zbiorki7.jpg">

                  </div>

                  <div class="col-md-8" data-aos="fade-left"> <img class="d-block w-100" style="display: block;
                margin: 0 auto; border-radius: 5px; " src="../assets/img/zbiorki4.jpg">

                  </div>
              </div>
          </div>
      </div>
</div>
